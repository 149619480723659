import {
  DEFAULT_PAGE_SIZE,
  IDeserializedPaginatedQuery,
  ILoadPaginatedForOrg,
  serializePaginatedQueryValues,
} from "@smartrr/shared/utils/paginatedQuery";
import { getQueryParamsForFilters } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { TableSettingProps } from "@vendor-app/constants/table";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const getBills = ({ queryParams }: ILoadPaginatedForOrg) => {
  return typedFrontendVendorApi.getReq("/bill", {
    query: serializePaginatedQueryValues(queryParams),
  });
};

export const getSubscriptionBillsByIdOrShopifyIdAPI = ({
  shopifySubscriptionId,
  queryParams,
}: ILoadPaginatedForOrg & { shopifySubscriptionId: string }) => {
  return typedFrontendVendorApi.getReq("/purchase-state/:shopifySubscriptionId/bills", {
    params: {
      shopifySubscriptionId,
    },
    query: queryParams ? serializePaginatedQueryValues(queryParams) : undefined,
  });
};

export function putBulkRetryBillsWithQuery({
  ids,
  tableProps,
  cpsId,
}: {
  ids: "all" | string[];
  tableProps?: TableSettingProps;
  cpsId?: string;
}) {
  let newQueryParams: IDeserializedPaginatedQuery | undefined = {
    ...tableProps,
    pageSize: tableProps?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: tableProps?.pageNumber ? tableProps.pageNumber - 1 : 0,
    orderBy: {
      [tableProps?.orderByField ?? "billingDate"]: tableProps?.orderByValue ?? "ASC",
    },
  };
  newQueryParams = getQueryParamsForFilters(newQueryParams, tableProps?.filter);

  return typedFrontendVendorApi.putReq("/bill/bulk-retry", {
    query: serializePaginatedQueryValues(newQueryParams),
    reqBody: {
      billIds: ids,
      cpsId,
    },
  });
}
export function putBulkCancelBillsWithQuery({
  ids,
  tableProps,
  cpsId,
}: {
  ids: "all" | string[];
  tableProps?: TableSettingProps;
  cpsId?: string;
}) {
  let newQueryParams: IDeserializedPaginatedQuery | undefined = {
    ...tableProps,
    pageSize: tableProps?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: tableProps?.pageNumber ? tableProps.pageNumber - 1 : 0,
    orderBy: {
      [tableProps?.orderByField ?? "billingDate"]: tableProps?.orderByValue ?? "ASC",
    },
  };
  newQueryParams = getQueryParamsForFilters(newQueryParams, tableProps?.filter);

  return typedFrontendVendorApi.putReq("/bill/bulk-cancel", {
    query: serializePaginatedQueryValues(newQueryParams),
    reqBody: {
      billIds: ids,
      cpsId,
    },
  });
}

export const loadBills =
  ({
    queryParams,
  }: ILoadPaginatedForOrg): SmartrrThunkAction<"LOADING_BILLS" | "ERROR_LOADING_BILLS" | "LOADED_BILLS"> =>
  dispatch =>
    vendorAsyncDispatch(
      getBills({ queryParams }),
      () =>
        dispatch({
          type: "LOADING_BILLS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_BILLS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      paginatedBills =>
        dispatch({
          type: "LOADED_BILLS",
          payload: {
            paginatedBills,
          },
        })
    );

export interface IBillBulkRetry {
  ids: string[] | "all";
  tableProps: TableSettingProps;
  addToast: (content: string) => void;
}

export const retryBillsInBulk =
  ({
    ids,
    addToast,
    tableProps,
  }: IBillBulkRetry): SmartrrThunkAction<
    "BULK_RETRYING_BILLS" | "ERROR_BULK_RETRYING_BILLS" | "BULK_RETRIED_BILLS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putBulkRetryBillsWithQuery({ ids, tableProps }),
      () =>
        dispatch({
          type: "BULK_RETRYING_BILLS",
          payload: undefined,
        }),
      failure => {
        addToast("Error retrying bill(s). Try to reload the page.");
        return dispatch({
          type: "ERROR_BULK_RETRYING_BILLS",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      paginatedBills => {
        addToast("Transaction(s) retried");
        return dispatch({
          type: "BULK_RETRIED_BILLS",
          payload: { paginatedBills },
        });
      }
    );

export const cancelBillsInBulk =
  ({
    ids,
    addToast,
    tableProps,
  }: IBillBulkRetry): SmartrrThunkAction<
    "BULK_CANCELING_BILLS" | "ERROR_BULK_CANCELING_BILLS" | "BULK_CANCELED_BILLS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      putBulkCancelBillsWithQuery({ ids, tableProps }),
      () =>
        dispatch({
          type: "BULK_CANCELING_BILLS",
          payload: undefined,
        }),
      failure => {
        addToast("Error canceling bill(s). Try to reload the page.");
        return dispatch({
          type: "ERROR_BULK_CANCELING_BILLS",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      paginatedBills => {
        addToast("Retry canceled");
        return dispatch({
          type: "BULK_CANCELED_BILLS",
          payload: { paginatedBills },
        });
      }
    );
